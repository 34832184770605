<template>
    <v-container class="assingment-container mb-4">
        <v-row class="py-1 px-4" v-if="bookData">
            <v-col cols="1" class="pa-0">
                <img
                    :src="`https://assets.beereaders.com/lbr/image/${bookData.book.resourceDir}/cover_small.jpg`"
                    alt="book-image"
                    class="border-radius-1 book-img"
                />
            </v-col>
            <v-col cols="2" class="pa-0">
                <div class="d-flex flex-column justify-center align-start px-3 h-100">
                    <div class="font-size-16 primary--text font-weight-bold">
                        <span class="cut-text">{{ bookData.book.title }}</span>
                    </div>
                    <div class="font-size-14 primary--text d-flex flex-row align-center">
                        <!--                        <country-flag :country="bookData.country" size="normal"></country-flag-->
                        <!--                        >-->
                        <span class="ml-1">{{ `${author}` }}</span>
                    </div>
                    <div class="d-flex flex-column justify-center align-start mt-auto">
                        <!--                        <div class="font-size-14 primary&#45;&#45;text">-->
                        <!--                            <span class="font-weight-light">650 L - K</span>-->
                        <!--                        </div>-->
                        <div class="font-size-12 primary--text">
                            <star-rating
                                v-model="bookData.book.rating"
                                :show-rating="false"
                                :star-size="15"
                                read-only
                            ></star-rating>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="2" class="pa-0">
                <div class="d-flex flex-column justify-start align-start px-3">
                    <div class="font-size-16 primary--text font-weight-bold">
                        <span>{{ $t('assigned_course') }}</span>
                    </div>
                    <div class="font-size-16 primary--text font-weight-regular mt-5">
                        <v-icon class="primary--text mr-1">mdi-clipboard-check-outline</v-icon
                        ><span class="text-capitalize">{{
                            `${$t('course')} ${bookData.course.name}`
                        }}</span>
                    </div>
                </div>
            </v-col>
            <v-col cols="2" class="pa-0">
                <div class="d-flex flex-column justify-start align-start px-3">
                    <div class="font-size-16 primary--text font-weight-bold">
                        <span>{{ $t('init_date') }}</span>
                    </div>
                    <div class="font-size-16 primary--text font-weight-regular mt-5">
                        <v-icon class="primary--text mr-1">mdi-calendar-minus</v-icon
                        ><span>{{ $moment(bookData.startingDate).format('MMM DD') }}</span>
                    </div>
                </div>
            </v-col>
            <v-col cols="2" class="pa-0">
                <div class="d-flex flex-column justify-start align-start">
                    <div class="font-size-16 primary--text font-weight-bold">
                        <span>{{ $t('end_date') }}</span>
                    </div>
                    <div class="font-size-16 primary--text font-weight-regular mt-5">
                        <v-icon class="primary--text mr-1">mdi-calendar-minus</v-icon
                        ><span>{{ $moment(bookData.evaluationDate).format('MMM DD') }}</span>
                    </div>
                </div>
            </v-col>
            <v-col cols="2" class="pa-0 ml-5">
                <div class="d-flex flex-column justify-space-around align-center h-100">
                    <v-btn
                        color="secondary"
                        class="text-capitalize"
                        outlined
                        rounded
                        small
                        :disabled="loading"
                        v-if="showEditBtn"
                        @click="goToModify"
                        ><v-icon dark dense class="mx-1">mdi-square-edit-outline </v-icon
                        >{{ $t('edit_period') }}</v-btn
                    >
                    <v-btn
                        color="secondary"
                        class="text-capitalize"
                        outlined
                        rounded
                        small
                        :disabled="loading"
                        @click="goToStats"
                        ><v-icon dark dense class="mx-1">mdi-chart-pie</v-icon
                        >{{ $t('check_stats') }}</v-btn
                    >
                    <v-btn
                        color="error darken-2"
                        class="text-capitalize mr-2"
                        outlined
                        rounded
                        small
                        :disabled="loading"
                        v-if="showRemoveBtn"
                        @click="deleteBookAssigment"
                        ><v-icon dark dense class="mx-1">mdi-delete-outline </v-icon
                        >{{ $t('delete') }}</v-btn
                    >
                </div>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbarError" :timeout="timeout" color="error">
            {{ $t('library.assigment_deleted_error') }}

            <template v-slot:action="{ attrs }">
                <v-btn color="error" icon text v-bind="attrs" @click="snackbarError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar data-cy="delete-assignment-success" v-model="snackbarSuccess" :timeout="timeout" color="success" outlined>
            {{ $t('library.assigment_deleted_success') }}

            <template v-slot:action="{ attrs }">
                <v-btn color="success" icon text v-bind="attrs" @click="snackbarSuccess = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import courseBookService from '../../../services/courseBook'

export default {
    name: 'BookAssingmentItem',
    props: {
        bookData: { type: Object, default: () => null },
        showRemoveBtn: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        showEditBtn: { type: Boolean },
    },
    data: () => ({
        snackbarSuccess: false,
        snackbarError: false,
        timeout: 2000,
    }),
    computed: {
        ...mapState({
            userId: (state) => state.userData.user.id,
        }),
        author() {
            const {
                book: { bookAuthorDTOList = [], authorDTO = { name: this.$t('anonymous') } },
            } = this.bookData

            return this.$utils.getAuthorName(authorDTO, bookAuthorDTOList, this.$t('anonymous'))
        },
    },
    methods: {
        ...mapActions('library', ['setAssignedBookList']),
        ...mapActions(['loadAssigment']),
        goToStats() {
            this.$router.push(`stats/${this.bookData.id}`)
        },
        goToModify() {
            const { id, book, course, startingDate, evaluationDate } = this.bookData
            const isPastBook = this.showRemoveBtn ? '' : '?pastBook=true'
            this.$router.push(
                `/teacher/library/book/${book.id}/${course.id}/${id}/${startingDate}/${evaluationDate}${isPastBook}`
            )
        },
        deleteBookAssigment() {
            const vm = this
            this.$modal.confirm(
                this.$t('library.confirm_delete'),
                this.$t('library.confirm_delete_msg'),
                async () => {
                    vm.loadAssigment()
                    const response = await courseBookService
                        .deleteCourseBook(vm.bookData.id)
                        .catch((e) => console.error(e))
                    if (response.content === true) vm.snackbarSuccess = true
                    else vm.snackbarError = true

                    const list = await courseBookService
                        .getListByTeacherId(vm.userId)
                        .catch((err) => console.error(err))
                    console.debug(list)
                    this.setAssignedBookList(list.content)
                    vm.loadAssigment()
                    courseBookService.getBookListByCourseAndDate({courseId: vm.bookData.course.id, date: this.$moment().format('YYYY-MM-DD') }, true)
                }
            )
        },
    },
}
</script>

<style scoped>
.assingment-container {
    box-sizing: border-box;
    border: 2px solid #e9e6f0;
    border-radius: 6px;
    background-color: #ffffff;
}

.book-img {
    display: block;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.cut-text {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
